















































import Vue from 'vue'
import { Component } from 'vue-property-decorator'

export default class ContactUsPopup extends Vue {
  public brandName: string = this.$store.state.userData.name
  public message: string = ''
  public busy: boolean = false
  public sitekey: string = process.env.VUE_APP_GRECAPTCHA_SITE_KEY
  public code = 'US+1'
  public mobile = ''

  contactUs () {
    let params: any = {
      message: this.message,
      contact_type: 'UNSUBSCRIBE_REQUEST',
      priority: 'high'
    }
    Vue.http.post('unsubscribe-request', params).then(
      (response: any) => {
        // Object.assign(this.$data, (this.$options.data! as any).apply(this));
        (this.$refs.UnsubscribepopupCloseButton as HTMLElement).click()
        this.$notify({ type: 'success', text: 'Unsubscribe Request Sent!' })
        this.$emit('success')
        this.busy = false
      },
      (response: any) => {
        this.$store.commit('hideMessage')
        this.$notify({
          type: 'error',
          text: 'An error occurred while submitting...'
        })
        this.busy = false
      }
    )
  }

  demo () {
    if (this.message && this.brandName) {
      this.busy = true
      this.$store.commit('showLoading')
      this.contactUs()
      // (this.$refs.demoCaptcha as VueRecaptcha).execute()
    } else {
      this.$notify({
        type: 'error',
        text: 'Please fill out all the required fields'
      })
    }
  }
}
