<template>
  <div>
    <div
      class=" subscription-details white-bg"
      v-if="subscriptionDetails.current_data_subscription"
    >
      <h3>Current Plan
        <small style="margin-left:20px;color:black;"> (*cancellation notice for this plan is 1 month)</small>

         <a href data-target="#unsubscribe-popup" data-toggle="modal" class="pull-right pickl-btn">Change/Cancel Plan</a></h3>

      <div class="col-xs-12 col-sm-6 col-md-6 part">
        <h4>{{subscriptionDetails.current_data_subscription.name}} Data Access Plan</h4>
        <span>${{subscriptionDetails.current_data_subscription.price}} / {{ subscriptionDetails.current_data_subscription.duration_type }}, </span>
        <span>activated: {{subscriptionDetails.current_data_subscription.created_at| moment("MM-DD-YYYY")}}</span>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-6 part">
        <h4>
          ${{subscriptionDetails.current_data_subscription.price}}/<small>{{ subscriptionDetails.current_data_subscription.duration_type }}</small>
        </h4>
        <span>BIlled {{ subscriptionDetails.current_data_subscription.duration_type }}ly</span>
      </div>
      <div class="clearfix"></div>
    </div>
    <div
      class=" subscription-details white-bg"
      v-else
    >
    <span v-if="subscriptionDetails.data_access_subscription_trial_expires != null" style="text-align:center;padding:3px;background-color: #890000; display: block;box-shadow:2px 3px 10px 1px;margin-top:5px;margin-bottom:5px;border-radius: 4px;color:white;">Your Free Data Access Subscription trail will ends on {{ subscriptionDetails.data_access_subscription_trial_expires | moment("MM-DD-YYYY") }}</span>
      <!-- <h3>Current Plan</h3> -->
      <div class="col-xs-12 col-sm-12 col-md-12 part">
        <!-- <h4>{{subscriptionDetails.current_subscription.name}}</h4>
        <span>${{subscriptionDetails.current_subscription.price}} / Pickl, </span> -->
        <h2><strong>NO CURRENT PLAN</strong></h2>
      </div>
      <div class="clearfix"></div>
    </div>
    <section class="pricing-section side-padd" id="pricing">
      <h2 style="font-size:150%;">PICKL: A complimentary, flexible vehicle for marketing, sales & consumer insight teams.</h2>
      <div class="pricing-table">
        <div v-for="pack in picklPacks" :key="pack.id" class="plan" :class="[ pack.best_value==1 ? 'featured' : '' ]">
          <div class="extra-info" v-if="pack.best_value==1">
            <h5>Best Value</h5>
          </div>
          <div class="plan-header">
            <h3 class="plan-title">{{pack.name}}</h3>
            <p style="margin-top:10px;">
              Pack will give you reports and all data access all you need to have.
            </p>
            <div class="plan-cost">
              <span class="plan-price" v-if="pack.name =='Monthly'">
                $29.00<small style="font-size:50%;font-weight:normal;">/mo*</small>
              </span>
              <span class="plan-price" v-if="pack.name =='Yearly'">
                $290<small style="font-size:50%;font-weight:normal;">/yr*</small>
              </span>
            </div>
          </div>
          <div class="plan-select" v-if="!subscriptionDetails.current_data_subscription" style="margin-bottom: 20px;">
            <a  data-target="#data-access-subscription-payment-popup" href="javascript:;" data-toggle="modal" @click="checkoutDataAccessSubscription(pack)">get Started</a>
          </div>
          <div class="plan-select" v-else>
            <a class="disabled-start-button" disabled href="javascript:;">Get Started</a>
          </div>
        </div>
        <!-- Plan [4] -->
      </div>
      <label style="text-align:center;display:block; margin-top:40px;">*Prices displayed in USD, based on 1 or 2 month cancellation policy per individual plan and monthly billing.</label>
      <DataAccessSubscriptionPaymentPopup :amount="totalAmount" @paymentSuccessfull="SubscribePack" :planName="planName" :selectedDurationType="selectedDurationType"/>
  </section>
</div>
</template>
<script>
import DataAccessSubscriptionPaymentPopup from '@/components/Brand/DataAccessSubscriptionPaymentPopup.vue'

export default {
  components: {
    DataAccessSubscriptionPaymentPopup
  },
  props: ['subscriptionDetails'],
  data () {
    return {
      picklPacks: [],
      selectedPack: {},
      selectedPackName: '',
      bNumberOfPickls: 30,
      eNumberOfPickls: 100,
      pNumberOfPickls: 400,
      numberOfPickls: 0,
      publishableKey: process.env.VUE_APP_STRIPE_API_KEY,
      loading: false,
      lineItems: [],
      successURL: process.env.VUE_APP_URL + '/subscription/success',
      cancelURL: process.env.VUE_APP_URL + '/subscription/cancel',
      totalAmount: 0,
      planName: '',
      selectedDurationType: ''
    }
  },
  mounted () {
    this.getSubscriptionPacks()
  },

  methods: {
    getSubscriptionPacks () {
      this.$http
        .get('data_access_subscriptions')
        .then(
          function (response) {
            this.picklPacks = response.body.data
          }
        )
    },
    showPicklsDropDown (pack) {
      this.selectedPackName = pack.name
    },
    checkoutDataAccessSubscription (pack) {
      this.selectedPack = pack
      this.totalAmount = pack.price
      this.planName = pack.name
      this.selectedDurationType = pack.duration_type
    },
    SubscribePack (token) {
      this.$store.commit('showLoading')
      if (this.eNumberOfPickls < 100 || this.bNumberOfPickls < 30 || this.pNumberOfPickls < 400) {
        this.$notify({ type: 'error', text: 'Invalid pickl count selected...', duration: -1 })
        return false
      }
      let brandId = this.$store.state.userData.brandId
      this.$http
        .post(`brands/${brandId}/subscribe-data-access-pack`, { 'token_details': token, 'pack_details': this.selectedPack })
        .then(
          function (response) {
            if (response.body.status === true) {
              this.$router.push('/brand/dashboard')
              this.$store.commit('showMessage', { message: 'Transaction<br /> Successful!' })
            } else {
              this.$notify({ type: 'error', text: response.body, duration: -1 })
              this.$store.commit('hideMessage')
            }
          },
          function (response) {
            this.$notify({ type: 'error', text: 'An error occurred while processing your transaction...', duration: -1 })
            this.$store.commit('hideMessage')
          }
        )
    }
  }

}
</script>
<style scoped>
.btn-select-box {
  width: 100%;
  border-color: #782b54;
  border-radius:5px;
  font-size: 13px;
  width: 90%;
  margin: auto;
  text-align: center;
  margin-top:10px;
}
.disabled-start-button {
  background-color: #b37499 !important;
}
.subscription-details {
  margin-bottom: 30px;
}

.subscription-details h3 {
  font-size: 22px;
  font-weight: 400;
  padding: 15px;
  margin: 0;
}

.subscription-details .part {
  text-align: center;
  background-color: #fafafc;
  border-top: 1px solid #e8e8ea;
  border-right: 1px solid #e8e8ea;
  padding: 20px 10px;
  font-size: 28px;
}

.subscription-details .part:last-child {
  border-right: none;
}

.subscription-details .part h4 {
  color: #12db6d;
  font-weight: 500;
  font-size: 1em;
  margin-top: 0;
  margin-bottom: 0.3em;
}

.subscription-details .part h4 small {
  font-weight: 400;
  color: inherit;
}

.subscription-details .part span {
  font-size: 0.65em;
  color: black;
}

.subscription-details .part span.btn-negative {
  font-weight: 400;
}

.subscription-details .part-alert h4 {
  color: #cb4f40;
}

@media only screen and (min-width: 992px) and (max-width: 1095px) {
  .subscription-details .part {
    font-size: 29px;
  }
}

@media only screen and (min-width: 1096px) and (max-width: 1199px) {
  .subscription-details .part {
    font-size: 24px;
  }
}

.pickl-btn {
    color: #ffffff;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 40px;
    font-size: 15px;
    font-weight: 800;
    display: inline-block;
    background-color: #782b54;
}
</style>
