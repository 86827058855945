














































































































































































































































































































import Vue from 'vue'
import { Watch, Component } from 'vue-property-decorator'
import Subscription from '@/views/Brand/Subscription.vue'
import DataAccessSubscriptionPacks from '@/components/Brand/DataAccessSubscriptionPacks.vue'
import BuyMeCreditsPopupVue from '@/components/Brand/BuyMeCreditsPopup.vue'
import UnsubscribeRequestPopup from '@/components/Brand/UnsubscribeRequestPopup.vue'

@Component({
  components: {
    Subscription,
    BuyMeCreditsPopupVue,
    DataAccessSubscriptionPacks,
    UnsubscribeRequestPopup

  }
})

export default class PaymentHistory extends Vue {
  public subscriptionHistory: any[] = []
  public picklHistory: any[] = []
  public limit = 100
  public offset = 0
  public picklLimit = 100
  public picklOffset = 0
  public rcLimit = 100
  public rcOffset = 0
  public rcpLimit = 100
  public rcpOffset = 0
  public currentCount = 0
  public totalCount = 0
  public historyCurrentCount = 0
  public historyTotalCount = 0
  public totalPaid = 0
  public totalRefunded = 0
  public query = ''
  public busy = true
  public showLoadMoreHistory = false
  public brandSubscriptionDetails: any[] = []
  public brandReimbursementCreditsLog: any[] = []
  public brandReimbursementCreditsPurchaseLog: any[] = []
  public buyMeCreditsConsumed : number = 0
  public buyMeCreditsPurchased : number = 0

  success () {
    this.$store.commit('showMessage', {
      message:
        "Got It <img src='/img/1f60a.png' class='emoticon' /><br /> Thank You"
    })
  }

  getSubscriptionHistory (clear = true) {
    this.busy = true
    this.$store.dispatch('getBrandInvoiceHistory', { limit: this.limit, offset: this.offset, query: this.query }).then((response) => {
      if (this.limit === response.options.limit && this.offset === response.options.offset && this.query === response.options.query) {
        if (clear) {
          this.subscriptionHistory = []
          this.currentCount = 0
          this.subscriptionHistory.push(...response.response.body.data)
        } else {
          response.response.body.data.forEach((x: any) => {
            let index = this.subscriptionHistory.findIndex((y) => y.key === x.key)
            if (index !== -1) {
              this.subscriptionHistory[index].value.push(...x.value)
            } else {
              this.subscriptionHistory.push(x)
            }
          })
        }
        this.totalCount = response.response.body.count
        this.currentCount += response.currentCount
        this.totalPaid = response.response.body.total_paid
        this.totalRefunded = response.response.body.total_refunded
        this.busy = false
      }
    }, response => {
      this.busy = false
    })
  }
  getPicklHistory (clear = true) {
    this.busy = true
    this.$store.dispatch('getBrandPicklHistory', { limit: this.picklLimit, offset: this.picklOffset }).then((response) => {
      if (this.picklLimit === response.options.limit && this.picklOffset === response.options.offset) {
        if (clear) {
          this.picklHistory = []
          this.historyCurrentCount = 0
          this.picklHistory.push(...response.response.body.data)
        } else {
          response.response.body.data.forEach((x: any) => {
            let index = this.picklHistory.findIndex((y) => y.key === x.key)
            if (index !== -1) {
              this.picklHistory[index].value.push(...x.value)
            } else {
              this.picklHistory.push(x)
            }
          })
        }
        this.historyTotalCount = response.response.body.count
        this.historyCurrentCount += response.currentCount
        this.busy = false
      }
    }, response => {
      this.busy = false
    })
  }

  getFormattedMonth (label: string) {
    // return new Date(label.)
  }

  loadMore () {
    this.offset += this.limit
    this.getSubscriptionHistory(false)
  }
  loadMorePicklHistory () {
    this.picklOffset += this.picklLimit
    this.getPicklHistory(false)
  }
  getCurrentSubscription () {
    this.$store.dispatch('getBrandCurrentSubscription', this.$store.state.userData.brandId).then((response: any) => {
      this.brandSubscriptionDetails = response.response.body.data
    }, response => {
    })
  }
  getBrandReimbursementCreditsLog () {
    this.$store.dispatch('getBrandReimbursementCreditsLog', { limit: this.rcLimit, offset: this.rcOffset, brandId: this.$store.state.userData.brandId }).then((response: any) => {
      this.brandReimbursementCreditsLog.push(...response.response.body.data)
      this.buyMeCreditsConsumed = response.response.body.sum
    }, response => {
    })
  }

  getBrandReimbursementCreditsPurchaseLog () {
    this.$store.dispatch('getBrandReimbursementCreditsPurchaseLog', { limit: this.rcpLimit, offset: this.rcpOffset, brandId: this.$store.state.userData.brandId }).then((response: any) => {
      this.brandReimbursementCreditsPurchaseLog.push(...response.response.body.data)
      this.buyMeCreditsPurchased = response.response.body.sum
    }, response => {
    })
  }
  created () {
    this.getSubscriptionHistory()
    this.getPicklHistory()
    this.getCurrentSubscription()
    this.getBrandReimbursementCreditsLog()
    this.getBrandReimbursementCreditsPurchaseLog()
    console.log(this.$route.query.tab)
    if (typeof this.$route.query.tab === 'undefined') {
      this.$router.push('/brand/payment-history?tab=subscription')
    }
  }
  @Watch('query')
  onSearch (value: string, oldValue: string) {
    Vue.common.delay(this.getSubscriptionHistory, 500)()
  }
}
